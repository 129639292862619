import React, { useState } from "react";
import { NavLink as RouterNavLink } from "react-router-dom";
import {
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  NavbarToggler,
  Collapse,
} from "reactstrap";
import "./Navbar.css";
import RPSLogo from "../../images/RPS-Logo.jpg";

const MyNavbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <header>
      <Navbar className="navbar" expand="md">
        <NavbarToggler onClick={toggle} aria-label="Toggle navigation" />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="mr-auto" navbar>
            <NavItem>
              <RouterNavLink exact="true" to="/" className="nav-link">
                Home
              </RouterNavLink>
            </NavItem>
            <NavItem>
              <RouterNavLink to="/showcase" className="nav-link">
                Showcase
              </RouterNavLink>
            </NavItem>
            <NavItem>
              <RouterNavLink to="/about" className="nav-link">
                About
              </RouterNavLink>
            </NavItem>
            <NavItem>
              <RouterNavLink to="/services" className="nav-link">
                Services
              </RouterNavLink>
            </NavItem>
            <NavItem>
              <RouterNavLink to="/contact" className="nav-link">
                Contact
              </RouterNavLink>
            </NavItem>
          </Nav>
        </Collapse>
        <div className="brand-and-nav">
          <NavbarBrand tag={RouterNavLink} to="/" className="mx-auto">
            <img
              className="logo"
              src={RPSLogo}
              alt="React Pro Solutions logo"
            />
          </NavbarBrand>
          <Nav className="ml-auto nav-link" navbar>
            <NavItem>
              <a href="tel:+15094815800" className="nav-link">
                Sales: (509) 481-5800
              </a>
            </NavItem>
          </Nav>
        </div>
      </Navbar>
    </header>
  );
};

export default MyNavbar;
