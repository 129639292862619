import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardTitle, Row, Col } from 'reactstrap';
import './Service.css';
import ContactButton from '../ContactButton/ContactButton';

const Services = () => {
    return (
        <div className='services-container'>
            <ContactButton />
            <h2 className="flash-title-dark">Our Services</h2>

            <Row className='justify-content-center'>
                {/* Core Features */}
                <Col md={6}>
                    <Card className='feature-card'>
                        <CardBody className='text-center'>
                            <CardTitle tag="h3" className='section-title'>Core Features</CardTitle>
                            <ul className='list-center'>
                                <li>Custom Web Application Design</li>
                                <li>Responsive Design</li>
                                <li>Basic SEO Setup</li>
                            </ul>
                        </CardBody>
                    </Card>
                </Col>
            </Row>

            <Row className='justify-content-center'>
                {/* Premium Add-ons */}
                <Col md={6}>
                    <Card className='feature-card'>
                        <CardBody className='text-center'>
                            <CardTitle tag="h3" className='section-title'>Premium Add-ons</CardTitle>
                            <ul className='list-center'>
                                <li>Advanced SEO Services</li>
                                <li>Online Scheduling</li>
                                <li>Online Deposits</li>
                                <li>Website Hosting and Domain Setup</li>
                                <li>Monthly Maintenance and Updates</li>
                                <li>Advanced Analytics and Reporting</li>
                                <li>Content Creation Services</li>
                                <li>E-commerce or Subscription Management</li>
                                <li>Training and Consultation</li>
                            </ul>
                        </CardBody>
                    </Card>
                </Col>
            </Row>

            <p className='service-flexibility'>We offer flexibility from single projects to ongoing services. <Link className="cross-link" to="/contact">Contact us</Link> to tailor a package that meets your specific needs.</p>
        </div>
    );
};

export default Services;

