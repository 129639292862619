import React from 'react';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import './App.css';
import ScrollToTop from './components/ScrollToTop';
import PreHeader from './components/PreHeader/PreHeader';
import MyNavbar from './components/Navbar/Navbar';
import SubHeader from './components/SubHeader/SubHeader';
import Home from './components/Home/Home';
import FeatureProjects from './components/FeaturedProjects/FeatureProjects';
import About from './components/About/About';
import Services from './components/Services/Services';
import Contact from './components/Contact/Contact';
import Footer from './components/Footer/Footer';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <Router>
      <Helmet>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-7618S5KCL4"></script>
        <script>
          {`
            /* global dataLayer, arguments */
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-7618S5KCL4');
          `}
        </script>
      </Helmet>
      <ScrollToTop />
      <div className='App'>
        <PreHeader />
        <MyNavbar />
        <SubHeader />
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/showcase" element={<FeatureProjects />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
