import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import './PreHeader.css'

const PreHeader = () => {
  return (
    <header>
        <p className='p-text'>
            Free consultation is available - <Link className='cross-link' to="/contact">Contact Us Today</Link> 
        </p>
    </header>
  )
}

export default PreHeader;
