import { Container, Row, Col } from "reactstrap";
import { NavLink as RouterNavLink } from "react-router-dom";
import {
  FaLinkedinIn,
  FaTwitterSquare,
  FaGithubSquare,
  FaDiscord,
} from "react-icons/fa";
import "./Footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row>
          <Col sm="4" className="text-center">
            <h3>
              <strong>Links</strong>
            </h3>
            <nav>
              <ul className="list-unstyled">
                <li>
                  <RouterNavLink className="gold" to="/">
                    Home
                  </RouterNavLink>
                </li>
                <li>
                  <RouterNavLink className="gold" to="/showcase">
                    Showcase
                  </RouterNavLink>
                </li>
                <li>
                  <RouterNavLink className="gold" to="/about">
                    About
                  </RouterNavLink>
                </li>
                <li>
                  <RouterNavLink className="gold" to="/services">
                    Services
                  </RouterNavLink>
                </li>
                <li>
                  <RouterNavLink className="gold" to="/contact">
                    Contact
                  </RouterNavLink>
                </li>
              </ul>
            </nav>
          </Col>
          <Col sm="4" className="text-center">
            <h3 className="footer-h3">
              <strong>Social</strong>
            </h3>
            <a
              className="btn btn-social-icon"
              href="https://github.com/MotivatedUser"
              aria-label="GitHub"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaGithubSquare className="footer-icon white" />
            </a>
            <a
              className="btn btn-social-icon"
              href="https://www.linkedin.com/in/d-altermatt-motivated-user"
              aria-label="LinkedIn"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaLinkedinIn className="footer-icon gold" />
            </a>
            <a
              className="btn btn-social-icon"
              href="https://twitter.com/altermatt_d"
              aria-label="Twitter"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaTwitterSquare className="footer-icon gold" />
            </a>
            <a
              className="btn btn-social-icon"
              href="https://discordapp.com/users/1034946698860052530"
              aria-label="Discord"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaDiscord className="footer-icon white" />
            </a>
          </Col>
          <Col sm="4" className="text-center">
            <h3 className="footer-h3">
              <strong>Contact</strong>
            </h3>
            <address>
              <a
                role="button"
                className="btn btn-link footer-link"
                href="tel:+15094815800"
                aria-label="Phone"
              >
                <p className="gold"> 1-509-481-5800</p>
              </a>
              <br />
              <a
                role="button"
                className="btn btn-link footer-link"
                href="mailto:reactivedoug@gmail.com"
                aria-label="Email"
              >
                <p className="gold">ReactiveDoug@Gmail.com</p>
              </a>
            </address>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
