import React from 'react';
import { Link } from 'react-router-dom';
import './ContactButton.css'

const ContactButton = () => {
  return (
    <Link to="/contact" className="sticky-button">
        <button>Contact Us</button>
      </Link>
  )
}

export default ContactButton