import React, { useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { useForm } from "react-hook-form";
import emailjs from "emailjs-com";
import "./NewsletterSignUp.css";
import "./NewsletterSignUp.css"

const NewsletterSignUp = () => {
  const [modal, setModal] = useState(true);
  const toggle = () => setModal(!modal);

  // Initialize emailjs
  useEffect(() => {
    emailjs.init(process.env.REACT_APP_EMAILJS_USER_ID);
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = (data) => {
    emailjs.send("service_nur4i14", "template_f8jsjmm", data).then(
      (result) => {
        console.log(result.text);
        alert("Thanks for joining our email list!");
        reset();
      },
      (error) => {
        console.log(error.text);
        alert("Failed to join email list. Please try again later.");
      }
    );
    toggle();
  };

  return (
    <Modal isOpen={modal} toggle={toggle} aria-labelledby="modal-title" style={{marginTop: '10%'}}>
      <Modal isOpen={modal} toggle={toggle} aria-labelledby="modal-title" style={{marginTop: '10%'}}>
  <ModalHeader id="modal-title" toggle={toggle}>Join Our Email List</ModalHeader>
  <form onSubmit={handleSubmit(onSubmit)}>
    <ModalBody>
      <label htmlFor="name">
        Your Name:
        <input
          id="name"
          aria-required="true"
          {...register("name", { required: true })}
        />
        {errors.name && <p aria-live="polite">This field is required</p>}
      </label>
      <br />
      <label htmlFor="businessName">
        Business Name:
        <input id="businessName" {...register("businessName")} />
      </label>
      <br />
      <label htmlFor="emailList">
        Email Address:
        <input
          id="emailList"
          type="email"
          aria-required="true"
          {...register("emailList", {
            required: true,
            pattern: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
          })}
        />
        {errors.emailList && errors.emailList.type === "required" && (
          <p aria-live="polite">Email is required</p>
        )}
        {errors.emailList && errors.emailList.type === "pattern" && (
          <p aria-live="polite">Please enter a valid email address</p>
        )}
      </label>
    </ModalBody>
    <ModalFooter>
      <Button color="primary" type="submit" aria-label="Join our email list">Join</Button>
    </ModalFooter>
  </form>
</Modal>
    </Modal>
  );
};

export default NewsletterSignUp;
