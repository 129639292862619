import React from 'react';
import ProjectsGrid from '../ProjectsGrid/ProjectsGrid.js';
import ContactButton from '../ContactButton/ContactButton.js';
import './FeaturedProjects.css';

const FeatureProjects = () => {
  return (
    <>
      <div className='ProjectsContainer'>
        <h3 className="flash-title projects-title">Our Portfolio: Turning Small Business Dreams Into Digital Reality</h3>
        
        <p className='ProjectsIntro'>
          At React Pro Solutions, we specialize in creating secure, responsive, and custom-tailored web applications that help small businesses stand out in the digital world. 
          Built with cutting-edge technologies like React, our SPAs offer unparalleled speed, scalability, and user experience.
        </p>
        
        <p className='ProjectsNote'>
          Below, you'll find a curated selection of our projects that not only meet but exceed client expectations. Hover over each card to learn more about the project.
        </p>
        
        <ProjectsGrid />
        
        <p className='ProjectsDisclaimer'>
          *Please note: The live sites may have undergone changes post-delivery and might not represent the final product as initially designed and developed by React Pro Solutions.*
        </p>
        <ContactButton />
      </div>
    </>
  );
}

export default FeatureProjects;
