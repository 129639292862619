export const projectsData = [
    
    {
        title: "Pawfect Kennels",
        image: process.env.PUBLIC_URL + "/images/PawfectKennels.jpg",
        url: "https://motivateduser.github.io/pet-kennel/",
        technologies:
          "React, ReactStrap, React-Modal, React-Slick, Bootstrap, React-Router-DOM, GH-Pages, React-Icons, Slick-Carousel, Web-Vitals, React-DOM",
        learning:
          "Pawfect Kennels showcases a sleek, pet-centric interface designed to connect pet owners with the ideal boarding experience. Enhanced by React's dynamic capabilities, this web app features a seamless, responsive layout powered by Bootstrap, with smooth navigation facilitated by React-Router-DOM. Engaging carousels powered by React-Slick highlight kennel options, while deployment on GitHub Pages underscores our commitment to accessible, reliable web solutions.",
      },
      {
        title: "Bed and Breakfast",
        image: process.env.PUBLIC_URL + "/images/BNBSiteImage.jpg",
        url: "https://motivateduser.github.io/bed-and-breakfast",
        technologies:
          "React, React-DOM, React-Router-DOM, React-Icons, ReactStrap, Bootstrap, React-Modal, React-Slick, Slick-Carousel, GH-Pages, Web-Vitals",
    
        learning:
          "Our Bed and Breakfast platform is the epitome of hospitality in the digital space. It's a React-driven web application where elegance meets functionality. Navigation flows effortlessly with React-Router-DOM, while ReactStrap and Bootstrap ensure a responsive experience on any device. Rich visual content delivered through React-Slick carousels and intuitive iconography from React-Icons come together to create an inviting online presence, deployed with precision on GitHub Pages.",
      },
      {
        title: "Healthy & Vibrant",
        image: process.env.PUBLIC_URL + "/images/healthy-image.jpg",
        url: "https://healthyandvibrant.org/",
        technologies:
          "React, React-DOM, React-Router-DOM, React-Hook-Form, React-Icons, React-Slick, ReactStrap, Bootstrap, Slick-Carousel, Web-Vitals, GH-Pages",
    
        learning:
          "Healthy & Vibrant's website is a testament to modern web aesthetics fused with user-friendly functionality. React frameworks underpin a site where health consultation bookings are made effortless through React-Hook-Form. Bootstrap and ReactStrap provide a visually appealing, responsive interface. Testimonials come to life with React-Slick sliders, making for an engaging user experience, all while ensuring easy access and navigation with React-Router-DOM, seamlessly deployed on GitHub Pages.",
      },
    {
      title: "Mobile Mower Mechanic App",
      image: process.env.PUBLIC_URL + "/images/MowerSite.jpg",
      url: "https://motivateduser.github.io/smallengine-mechanic-webapp/",
      technologies:
        "React, ReactStrap, React-Modal, React-Slick, Bootstrap, React-Router-DOM, GH-Pages, React-Icons, Slick-Carousel, Web-Vitals, React-DOM",
      learning:
        "This application is a toolkit for efficiency, enabling small engine repair professionals to engage with clients through a polished React interface. The use of Bootstrap ensures the app responds fluidly across devices, creating a professional online presence. Client-side routing is handled with finesse by React-Router-DOM, while React-Slick adds an interactive dimension to service listings, ensuring a smooth user journey from discovery to service engagement, all deployed via GitHub Pages.",
    },
    {
      title: "Pest Control App",
      image: process.env.PUBLIC_URL + "/images/PestSite.jpg",
      url: "https://www.critterquiter.com",
      technologies:
        "React, ReactStrap, React-Modal, React-Slick, Bootstrap, React-Router-DOM, GH-Pages, React-Icons, Slick-Carousel, Web-Vitals, React-DOM",
      learning:
        "The Pest Control App represents a fusion of cutting-edge design and practical functionality. Built on React and adorned with Bootstrap's responsive design, it provides a dynamic platform for pest control services. Modal interactions through React-Modal enhance user engagement, while service showcases glide effortlessly with React-Slick. Its deployment on GitHub Pages demonstrates an efficient, streamlined process, making this app not just a tool but a showcase of innovation in web app technology.",
    },
    {
      title: "Lawn Care App",
      image: process.env.PUBLIC_URL + "/images/LawncareSite.jpg",
      url: "https://www.yougrowwecut.com",
      technologies:
        "React, ReactStrap, React-Modal, React-Slick, Bootstrap, React-Router-DOM, GH-Pages, React-Icons, Slick-Carousel, Web-Vitals, React-DOM",
      learning:
        "Delivering a crisp, intuitive user experience, the Lawn Care App is a digital haven for all lawn maintenance needs. By harnessing React's robust ecosystem, the application boasts a vibrant interface with ReactStrap that adapts seamlessly to different devices. Client interactions are fluid, thanks to the smooth transitions of React-Router-DOM, while the vividness of React-Icons adds a touch of visual finesse. Each feature, including service inquiries via interactive modals, contributes to a streamlined process, reflecting our dedication to elevating lawn care services through technology.",
    },
    {
      title: "Fishing Guide App",
      image: process.env.PUBLIC_URL + "/images/FishingSite.jpg",
      url: "https://motivateduser.github.io/fishing-webapp/",
      technologies:
        "React, ReactStrap, React-Modal, React-Slick, Bootstrap, React-Router-DOM, GH-Pages, React-Icons, Slick-Carousel, Web-Vitals, React-DOM",
      learning:
        "Angling for success, the Fishing Guide App serves as an essential companion for the modern fisherman. Developed with React, it offers a user-friendly platform that combines ReactStrap's responsive design with the engaging motion of React-Slick carousels. The app's interface is enriched with React-Icons, providing an inviting aesthetic. Seamless navigation ensured by React-Router-DOM and the deployment on GitHub Pages illustrates our commitment to providing accessible, navigable, and compelling digital resources for fishing enthusiasts.",
    },
    {
      title: "Mobile Auto Mechanic App",
      image: process.env.PUBLIC_URL + "/images/MobileAutoSite.jpg",
      url: "https://motivateduser.github.io/mobile-mechanic-webapp/",
      technologies:
        "React, ReactStrap, React-Modal, React-Slick, Bootstrap, React-Router-DOM, GH-Pages, React-Icons, Slick-Carousel, Web-Vitals, React-DOM",
      learning:
        "This Mobile Auto Mechanic App is the embodiment of convenience on the go, providing a mobile-first approach to auto repair services. React and its diverse libraries empower the application with responsive ReactStrap components and slick carousels to showcase services dynamically. React-Modal enriches user interaction, while the responsive design ensures accessibility across devices. The application's deployment on GitHub Pages stands as a testament to our dedication to delivering comprehensive and accessible automotive solutions.",
    },
   
  
    {
      title: "React Pro Solutions LLC",
      image: process.env.PUBLIC_URL + "/images/ScreenshotWebsite.webp",
      url: "https://reactprosolutions.com/",
      technologies:
        "React, React-DOM, React-Router-DOM, React-Hook-Form, React-Icons, React-Bootstrap, ReactStrap, Bootstrap, EmailJS, Redux Toolkit, Web-Vitals, GH-Pages",
  
      learning:
        "React Pro Solutions LLC's digital presence is a testament to our commitment to creating high-caliber web applications. This website is a showcase of React excellence, with form management refined by React-Hook-Form and responsive design powered by React-Bootstrap and ReactStrap. The integration of EmailJS provides a seamless communication flow, while Redux Toolkit's state management ensures a robust and responsive user interface. Our expertise in React is further underscored by the site's performance metrics, optimized with Web-Vitals and made publicly available on GitHub Pages, symbolizing our dedication to creating top-tier digital experiences.",
    },
    
  ];
  