import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import emailjs from "emailjs-com";
import "./Contact.css";

const Contact = () => {
  useEffect(() => {
    emailjs.init(process.env.REACT_APP_EMAILJS_USER_ID);
  }, []);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
  } = useForm();
  const watchHasWebsite = watch("hasWebsite", false);
  const watchIsReferred = watch("isReferred", false);

  const onSubmit = (data) => {
    emailjs.send("service_nur4i14", "template_i5ni4s8", data).then(
      (result) => {
        console.log(result.text);
        alert("Email successfully sent!");
        reset();
      },
      (error) => {
        console.log(error.text);
        alert("Failed to send email. Please try again later.");
      }
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} aria-label="Contact form">
      <label htmlFor="contactName">
        Contact Name:
        <input
          id="contactName"
          {...register("contactName", { required: true })}
        />
        {errors.contactName && <p>This field is required</p>}
      </label>
      <br />
      <label htmlFor="phoneNumber">
        Phone Number:
        <input
          id="phoneNumber"
          {...register("phoneNumber", { required: true })}
        />
        {errors.phoneNumber && <p>This field is required</p>}
      </label>
      <br />
      <label htmlFor="email">
        Email Address:
        <input
          id="email"
          type="email"
          {...register("email", {
            required: true,
            pattern: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
          })}
        />
        {errors.email && errors.email.type === "required" && (
          <p>Email is required</p>
        )}
        {errors.email && errors.email.type === "pattern" && (
          <p>Please enter a valid email address</p>
        )}
      </label>
      <br />

      <label htmlFor="hasWebsite" className="checkbox-container">
        Do you currently have a website?
        <input
          id="hasWebsite"
          className="checkbox-size"
          type="checkbox"
          {...register("hasWebsite")}
        />
      </label>
      {watchHasWebsite && (
        <label htmlFor="websiteUrl">
          Website URL:
          <input
            id="websiteUrl"
            {...register("websiteUrl", { required: true })}
          />
          {errors.websiteUrl && <p>This field is required</p>}
        </label>
      )}
      <br />
      <label htmlFor="projectGoal">
        What is the goal of your project?
        <br />
        <textarea
          id="projectGoal"
          {...register("projectGoal", { required: true })}
        />
        {errors.projectGoal && <p>This field is required</p>}
      </label>
      <br />
      <label htmlFor="targetAudience">
        Who is your target audience?
        <br />
        <input
          id="targetAudience"
          {...register("targetAudience", { required: true })}
        />
        {errors.targetAudience && <p>This field is required</p>}
      </label>
      <br />
      <label htmlFor="deadline">
        Do you have a specific deadline for the project?
        <input id="deadline" type="date" {...register("deadline")} />
      </label>
      <br />
      <label htmlFor="requiredFeatures">
        What features and functionality are you looking for in the app?
        <textarea
          id="requiredFeatures"
          {...register("requiredFeatures", { required: true })}
        />
        {errors.requiredFeatures && <p>This field is required</p>}
      </label>
      <br />
      <label htmlFor="designPreferences">
        Do you have any design preferences or branding guidelines?
        <textarea id="designPreferences" {...register("designPreferences")} />
      </label>
      <br />
      <label htmlFor="budget">
        What is your budget for the project?
        <br />
        <input id="budget" type="number" {...register("budget")} />
      </label>
      <br />
      <label htmlFor="maintenanceNeeded" className="checkbox-container">
        Do you need ongoing maintenance and updates after the project is
        completed?
        <div>
          <input
            id="maintenanceNeeded"
            className="checkbox-size"
            type="checkbox"
            {...register("maintenanceNeeded")}
          />
        </div>
      </label>
      <br />
      <label htmlFor="examples">
        Can you provide examples of other websites/apps that you like?
        <textarea id="examples" {...register("examples")} />
      </label>
      <br />
      <label htmlFor="contentCreation">
        Will you need help with content creation, or will you provide all the
        content?
        <select
          id="contentCreation"
          {...register("contentCreation", { required: true })}
        >
          <option value="">Select...</option>
          <option value="needHelp">Need help with content creation</option>
          <option value="willProvide">Will provide all content</option>
        </select>
        {errors.contentCreation && <p>This field is required</p>}
      </label>
      <br />
      <label htmlFor="integrations">
        Do you need any specific integrations?
        <textarea id="integrations" {...register("integrations")} />
      </label>
      <br />
      <label
        htmlFor="multiLanguageOrAccessibility"
        className="checkbox-container"
      >
        Will you need multi-language support?
        <div>
          <input
            id="multiLanguageOrAccessibility"
            className="checkbox-size"
            type="checkbox"
            {...register("multiLanguageOrAccessibility")}
          />
        </div>
      </label>
      <br />
      <label htmlFor="otherRequests">
        Are there any other specific needs or requests for the project?
        <textarea id="otherRequests" {...register("otherRequests")} />
      </label>
      <br />
      <label htmlFor="isReferredBy" className="checkbox-container">
        Were you referred by someone?
        <div>
          <input
            id="isReferred"
            className="checkbox-size"
            type="checkbox"
            {...register("isReferred")}
          />
        </div>
      </label>

      {watchIsReferred && (
        <label htmlFor="referredBy">
          Referred By:
          <input
            id="referredBy"
            {...register("referredBy", { required: true })}
          />
          {errors.referredBy && <p>This field is required</p>}
        </label>
      )}
      <br />
      <button type="submit">Submit</button>
    </form>
  );
};

export default Contact;
