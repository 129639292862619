import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import './SubHeader.css';

const SubHeader = () => {
    return (
        <Container fluid >
            <Row>
                <Col className="container-motto">
                    <p className="motto">Creating web apps that React to your business needs</p>
                </Col>
            </Row>
        </Container>
    );
}

export default SubHeader;
