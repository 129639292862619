import React, { useEffect, useRef, useState } from "react";
import "./VideoComponent.css";

const VideoComponent = () => {
  const [isVideoEnded, setVideoEnded] = useState(false);
  const iframeRef = useRef(null);

  const playAgain = () => {
    if (iframeRef.current) {
      iframeRef.current.src = "https://youtu.be/GVGueo-kHtI?si=k1uRpvYrT2ZzDCRD";
      setVideoEnded(false);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (iframeRef.current) {
        iframeRef.current.src += "&autoplay=1";
      }
    }, 2000); // 2-second delay before autoplay

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <div className="video-container" aria-label="Video presentation of React Pro Solutions">
      <iframe
        title='React Pro Solutions Q & A'
        ref={iframeRef}
        className="home-video"
        src="https://www.youtube.com/embed/GVGueo-kHtI?enablejsapi=1&mute=1"
        frameBorder="0"
        allow="autoplay; encrypted-media"
        allowFullScreen
        tabIndex="0"
        onEnded={() => setVideoEnded(true)}
      ></iframe>

      {isVideoEnded && (
        <button 
          onClick={playAgain} 
          className="play-again-button"
          aria-label="Play Video Again"
          tabIndex="0"
        >
          Play Again
        </button>
      )}
    </div>
  );
};

export default VideoComponent;
