import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Button } from "reactstrap";
import "./Home.css";
import NewsletterSignUp from "../NewsletterSignUp/NewsletterSignUp";
import ContactButton from "../ContactButton/ContactButton";
import VideoComponent from "../VideoComponent/VideoComponent";
import promisePic from "../../images/promisePic.webp";

const Home = () => {
  const [showNewsletterSignUp, setShowNewsletterSignUp] = useState(false);
  const toggleNewsletterSignUp = () =>
    setShowNewsletterSignUp(!showNewsletterSignUp);

  return (
    <Container className="home">
      <Row className="home-row">
        <Col md="6" className="home-text">
          <header>
            <h2 className="flash-title" >Tech is Your Friend; Let Me Introduce You</h2>
          </header>

          <p>
            With a decade of experience as a small service business owner, I
            understand the unique challenges you face.{" "}
            <Link className="cross-link" to="/about">Learn more about our story</Link>
          </p>

          <p>
            A robust React application isn't just a nice-to-have—it's a
            strategic asset that can drastically improve your customer
            acquisition and retention rates. Whether it's through real-time
            updates, seamless navigation, or intelligent analytics, we craft
            solutions that make your service business more appealing and
            accessible to your customers.
          </p>
        </Col>
        <Col md="6" aria-label="Video Section">
          <VideoComponent />
        </Col>
      </Row>

      <Row className="home-row">
        <Col md="6">
          <img
            src={promisePic}
            alt="Graphic symbolizing a promise"
            className="home-image"
          />
        </Col>

        <Col md="6" className="home-text">
          <header>
            <h2 className="flash-title">Your Challenges, Our Solutions</h2>
          </header>
          <ul>
            <li>
              <strong>
                <Link className="cross-link" to="/services">FAQs</Link>
              </strong>
              : Confused customers often mean lost revenue. Our interactive FAQ
              sections help answer queries instantly.
            </li>
            <li>
              <strong>
                <Link className="cross-link" to="/services">Printable Forms</Link>
              </strong>
              : Make the traditional paperwork hassle-free with easy-to-access,
              printable forms.
            </li>
            <li>
              <strong>
                <Link className="cross-link" to="/contact">Form Submission</Link>
              </strong>
              : Enable smooth online form submissions, making it convenient for
              both you and your customers.
            </li>
            <li>
              <strong>
                <Link className="cross-link" to="/services">Online Scheduling</Link>
              </strong>
              : Overcome scheduling chaos with our easy-to-use online
              appointment system.
            </li>
          </ul>
        </Col>
      </Row>

      <Row>
        <Col className="center-button">
        <p>
          Interested in a custom comparison for your business?{" "}
          <Link className="cross-link" to="/contact">Contact us</Link>.
        </p>
        </Col>
      </Row>

      <Row className="home-row">
  <Col className="home-text">
    <header>
      <h2 className="flash-title">Why Choose React Pro Solutions vs. Other Options</h2>
    </header>
    <p className="intro-text">
      We understand you have options when it comes to building your web presence. From large corporations to template-based platforms, each has its own set of features. But if you value site ownership, cost-effectiveness, customization, and scalability—particularly with the power of React—then let's see how React Pro Solutions stacks up.
    </p>
          <div className="responsive-table">
          <table className="comparison-table">
          <thead>
          <tr>
            <th>Features</th>
            <th>React Pro Solutions</th>
            <th>Template-Based Platforms</th>
            <th>Large Corporations</th>
          </tr>
        </thead>
            <tbody>
              <tr>
                <td>Site Ownership</td>
                <td className="green">✔️</td>
                <td>❌</td>
                <td className="green">✔️</td>
              </tr>
              <tr>
                <td>Cost Over 10 Years</td>
                <td className="green">💲</td>
                <td className="green">💲💲</td>
                <td className="green">💲💲💲</td>
              </tr>
              <tr>
                <td>Familiar Faces</td>
                <td className="green">✔️</td>
                <td>❌</td>
                <td>❌</td>
              </tr>
              <tr>
                <td>Customization</td>
                <td className="green">✔️</td>
                <td>❌</td>
                <td className="green">✔️</td>
              </tr>
              <tr>
                <td>Ads & Revenue Control</td>
                <td className="green">✔️</td>
                <td>❌</td>
                <td>❌</td>
              </tr>
              <tr>
                <td>Scalability</td>
                <td className="green">✔️</td>
                <td>❌</td>
                <td className="green">✔️</td>
              </tr>
            </tbody>
          </table>
          </div>
        </Col>
      </Row>
      <Col className="center-button">
        <Button onClick={toggleNewsletterSignUp} className="newsletter-button">
          Join Our Quarterly Newsletter
        </Button>
        {showNewsletterSignUp && <NewsletterSignUp />}
      </Col>

      <ContactButton />
    </Container>
  );
};

export default Home;