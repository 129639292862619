import React from "react";
import { Container, Row, Col } from "reactstrap";
import "./About.css";
import dougPic from "../../images/dougPic.webp";
import knowledgeTree from "../../images/knowledgeTree.webp";
import achievements from "../../images/achievements.webp";
import ContactButton from "../ContactButton/ContactButton";
import { Link } from "react-router-dom";

const About = () => {
  return (
    <Container className="about-container">
      <ContactButton />
      {/* What We Do */}
      <Row>
        <Col lg="6">
          <h2 className="flash-title">What We Do</h2>
          <p>
            At <Link className="cross-link" to="/" >React Pro Solutions</Link> , we specialize in creating secure, responsive, and visually stunning web apps for small businesses. Leveraging a tech stack that includes React, Bootstrap, and an assortment of specialized libraries, we deliver <Link className="cross-link" to="/services">web solutions</Link>  that allow smaller enterprises to compete effectively in the digital marketplace.
          </p>
        </Col>
        <Col lg="6">
          <img
            className="about-image"
            src={knowledgeTree}
            alt="What we specialize in"
          />
        </Col>
      </Row>

      {/* How We Got Here */}
      <Row>
        <Col>
          <h2 className="flash-title">How We Got Here</h2>
          <p>
            Our journey began with the founder, Doug Altermatt, who carried over a decade of business management experience into this venture. With a robust educational background in Full Stack Development and Business Management, Doug decided to combine his technical skills with business acumen, culminating in the formation of React Pro Solutions.
          </p>
        </Col>
      </Row>

      {/* Why We Are Here */}
      <Row>
        <Col>
          <h2 className="flash-title">Why We Are Here</h2>
          <p>
            Our mission is to bridge the digital divide for small businesses. We believe in leveling the playing field by providing cutting-edge, reliable, and secure web solutions that enhance online visibility and customer engagement for smaller enterprises.
          </p>
        </Col>
      </Row>

      {/* Our Founders */}
      <Row>
        <Col lg="6">
          <img
            className="about-image"
            src={dougPic}
            alt="Our Founders"
          />
        </Col>
        <Col lg="6">
          <h2 className="flash-title">Our Founders</h2>
          <p>
            Led by the founders Doug Altermatt and Melissa L. Altermatt, our team brings a synergy of technical prowess and financial expertise. Doug oversees the technical direction, while Melissa ensures financial sustainability, together forming a robust leadership team.
          </p>
        </Col>
      </Row>

      {/* How We Work */}
      <Row>
        <Col>
          <h2 className="flash-title">How We Work</h2>
          <p>
            Collaboration and continuous learning are at the heart of our operations. We maintain a client-centric approach, keeping open channels of communication throughout the project lifecycle. This enables us to adapt and deliver solutions that are in sync with our clients' evolving needs.
          </p>
        </Col>
      </Row>

      {/* Achievements and Testimonials */}
      <Row>
        
        <Col lg="6">
          <h2 className="flash-title">Achievements & Testimonials</h2>
          <p>
            While we are relatively new, our team brings a history of achievements and satisfied clients. With top rankings on platforms like Google and Angieslist in previous ventures, we're committed to garnering similar accolades in our current role. Our client testimonials affirm our commitment to quality and customer satisfaction.
          </p>
          <blockquote>
            <div className="review-frame">
              <p>
                "I really ❤ it!" - Roxane C., Spokane Valley, WA
              </p>
            </div>
          </blockquote>
        </Col>
        <Col lg="6">
          <img
            className="about-image"
            src={achievements}
            alt="Our Milestones"
          />
        </Col>
      </Row>
    </Container>
  );
};

export default About;
